<template>
  <div class="page-container dashboard-page">
    <el-form>
      <el-form-item label="已导入问卷表人数：">
        {{ quCount.studentCount }}
      </el-form-item>
      <el-form-item label="已导入问卷表次数：" style="margin-top: -20px">
        {{ quCount.totalCount }}
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="importExcel.dialog = true">导入问卷表</el-button>
        <el-button type="success" @click="clickDashboardBtn">打开数据看板</el-button>
      </el-form-item>
    </el-form>

    <!--导入input-->
    <input
        id="importFile"
        type="file"
        style="display: none"
        @change="(files)=>importFileChange(files)"
    >
    <!--导入弹窗-->
    <el-dialog
        title="导入问卷表"
        :visible.sync="importExcel.dialog"
        :close-on-click-modal="false"
        width="500px"
        center
        v-el-drag-dialog>
      <div class="dialog-container">
        <el-form>
          <el-form-item label="导入模板(Excel):">
            <span style="margin-right: 15px">问卷表模板-230912.xlsx</span>
            <el-button type="default" size="mini" @click="clickDownloadBtn()">下载</el-button>
          </el-form-item>
          <el-form-item label="错误信息:" v-if="importExcel.errMsg">
            <span style="color:#e47470">{{ importExcel.errMsg }}</span>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="default"
                   @click="importExcel.dialog=false">取 消
        </el-button>
        <el-button type="success"
                   @click="clickImportBtn()" :loading="importExcel.doing">导入问卷
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {dateFormat, numberFormat} from "@/filter/filter";
import elDragDialog from "@/directive/el-drag-dialog";
import {downloadFile} from "@/utils/common";
import {getSchoolQuestionnaireCount} from "@/api/erp/ExpQuestionnaireApi";
import {msg_success} from "@/utils/ele_component";
import {ExpQuestionnaireModel} from "@/model/erp/ExpQuestionnaireModel";

import {dashboardV2SchoolList} from "@/config/ExpAuthSchoolConfig";

export default {
  name: "dashboard",
  // 过滤器
  filters: {
    dateFormat: dateFormat
  },
  directives: {
    elDragDialog
  },
  computed: {
    ...mapState({
      userInfo: state => state.user
    })
  },
  data() {
    return {
      dashboardV2SchoolList:dashboardV2SchoolList,
      importExcel: {
        doing: false,
        dialog: false,
        errMsg: "",
      },
      quCount: {
        studentCount: 0,
        totalCount: 0,
      }
    }
  },
  mounted() {
    let userInfo = this.userInfo
    this.getSchoolQuestionnaireCount()
  },
  methods: {
    // 获取学校的调查表数量
    async getSchoolQuestionnaireCount() {
      let [data,] = await getSchoolQuestionnaireCount({
        schoolId: this.userInfo.schoolId
      })
      this.quCount = data.data
    },
    // 点击打开数据看板按钮
    clickDashboardBtn() {
      if(dashboardV2SchoolList.indexOf(this.userInfo.schoolId)!==-1){
        window.open("/dashboard/v2/index?schoolId="+this.userInfo.schoolId)
      }else{
        window.open("/dashboard/v1/index?schoolId="+this.userInfo.schoolId)
      }

    },
    // 点击了导入按钮
    clickImportBtn() {
      const uploader = document.getElementById('importFile')
      uploader.click()
    },
    // 选择了要导入的文件
    async importFileChange(files) {
      const file = files.target.files[0]
      document.getElementById('importFile').value = ''
      this.importExcel.doing = true
      let importResult = await ExpQuestionnaireModel.import(file, this.userInfo.schoolId)
      if (importResult === true) {
        msg_success('导入调查表成功!')
        this.importExcel.dialog = false
        this.importExcel.errMsg = ""
        window.location.reload()
      } else {
        this.importExcel.errMsg = importResult
      }
      this.importExcel.doing = false
    },
    // 点击下载导入模板按钮
    clickDownloadBtn() {
      downloadFile("https://resouce.cdzyhd.com/excel/%E9%97%AE%E5%8D%B7%E8%A1%A8%E6%A8%A1%E6%9D%BF-230912.xlsx", "问卷表模板-230912.xls")
    },
  }
}
</script>

<style>
</style>
<style scoped lang="less">


</style>
